




















































































































@import '~variables'

$sidebar-header-size = 50px

.sidebar-item
  display flex
  flex-direction column
  border-bottom $grid-border
  &--small
    .header
      padding 12px 25px 12px 12px
    .header__title
      font-weight 400
      font-size $fs-h2
  &--collapsed
    flex-shrink 0
    border-bottom none
    .header__title
      color lighten($dark, 30%) !important
  .header
    display flex
    flex-shrink 0
    padding $space-1
    height $sidebar-header-size+30
    border-bottom $grid-border
    background-color white
    cursor pointer
    user-select none
    flex-direction column
    &:hover
      .header__title
        color $black !important
    .header__icon
      margin-right 8px
      width 30px
      height 30px
    .header__title
      color $black
      text-transform uppercase
      font-weight 300
      font-size $nfs-big
    .header__button
      margin-left auto
      border-radius $border-radius-rounded
  .collapse-container
    display flex
    flex-direction column
  .main
    display flex
    background linear-gradient($light 10px, white 100px)

.sub-text
  margin-left $space-2
  font-weight 200
  font-size $fs-h1
  text-overflow ellipsis
  overflow hidden
  white-space nowrap

.sidebar-item--main
  .sidebar-item__header, .sidebar-item__main
    background-color $light
