@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.container {
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 10px 20px 10px;
  box-shadow: 0px 5px 10px 0px #9c9c9c;
  margin: 0 auto;
  position: relative;
  width: 95%;
}
.container .close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
.container .container__title {
  text-align: left;
  width: 100%;
  font-weight: 700;
  font-size: 1.6rem;
  color: #000;
}
.container .example-container {
  display: flex;
  flex-direction: column;
}
.container .example-container .example {
  margin: 30px 0 0 10px;
  display: flex;
  flex-direction: column;
}
.container .example-container .example .example__title {
  font-size: 1.3rem;
  color: #000;
  font-weight: 700;
}
.container .example-container .example .example__title .example__button {
  margin-right: 10px;
}
.container .example-container .example .example__description {
  font-size: 1.1rem;
  margin: 5px 0 0 5px;
  color: #464749;
}
.container .example-container .example .example__oql {
  margin-top: 15px;
  background-color: #f3f1ef;
  border-radius: 3px;
  padding: 10px;
}
/*# sourceMappingURL=src/app/pages/oql/components/display/query-helper.css.map */