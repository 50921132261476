



































































































































@import '~variables'

.building-facts
  padding 0 !important
  min-height auto
  height 100%
  .left-sidebar >>> aside
    position fixed
    top 50px
    overflow visible // to see the drawer
    padding 25px
    box-shadow $shadow-elevation-3
    .sidebar-item
      position relative
      .helper-shortcut
        position absolute
        top 60px
        right 6px
        cursor pointer
      .input-container
        display flex
        flex-direction column
        align-items center
        .sidebar__query
          padding 18px
          width 100%
          outline none
          border solid 3px $silver
          background-color $light
          text-align justify
          font-size $fs-h2
          line-height 27px
          resize none
          .sidebar__query textarea
            color black
        .sidebar__button
          margin-top 15px
          width 25%
  .left-sidebar.no-transition >>> aside
    transform translateX(0px)
  .sidebar-out
    z-index 2

.viewer
  position relative
  padding 10px
  .results-table
    margin 10px auto
    background-color white
  .spinner-loader
    position absolute
    top 300px
    left 50%
    margin-left -40px

::-webkit-scrollbar
  width 6px

::-webkit-scrollbar-track
  background #cacaca

::-webkit-scrollbar-thumb
  background #828282

::-webkit-scrollbar-thumb:hover
  background #000

.fade-enter-active, .fade-leave-active
  opacity 1
  transition all 200ms ease

.fade-enter, .fade-leave-to
  opacity 0
  transition all 200ms ease
