
































































@import '~variables'

.container
    height 100%
    background-color white
    border-radius 5px
    padding 10px 10px 20px 10px
    box-shadow 0px 5px 10px 0px #9c9c9c
    margin 0 auto
    position relative
    width 95%
    .close-button
        position absolute
        right 10px
        top 10px
    .container__title
        text-align left
        width 100%
        font-weight 700
        font-size $fs-h1
        color black
    .example-container
        display flex
        flex-direction column
        .example
            margin 30px 0 0 10px
            display flex
            flex-direction column
            .example__title
                font-size $fs-h2
                color black
                font-weight 700
                .example__button
                    margin-right 10px
            .example__description
                font-size $fs-h3
                margin 5px 0 0 5px
                color $dark
            .example__oql
                margin-top 15px
                background-color $light
                border-radius 3px
                padding 10px
