@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.sidebar-item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f4f4f4;
}
.sidebar-item--small .header {
  padding: 12px 25px 12px 12px;
}
.sidebar-item--small .header__title {
  font-weight: 400;
  font-size: 1.3rem;
}
.sidebar-item--collapsed {
  flex-shrink: 0;
  border-bottom: none;
}
.sidebar-item--collapsed .header__title {
  color: #7c7e81 !important;
}
.sidebar-item .header {
  display: flex;
  flex-shrink: 0;
  padding: 8px;
  height: 80px;
  border-bottom: 1px solid #f4f4f4;
  background-color: #fff;
  cursor: pointer;
  user-select: none;
  flex-direction: column;
}
.sidebar-item .header:hover .header__title {
  color: #000 !important;
}
.sidebar-item .header .header__icon {
  margin-right: 8px;
  width: 30px;
  height: 30px;
}
.sidebar-item .header .header__title {
  color: #000;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 2.6rem;
}
.sidebar-item .header .header__button {
  margin-left: auto;
  border-radius: 20px;
}
.sidebar-item .collapse-container {
  display: flex;
  flex-direction: column;
}
.sidebar-item .main {
  display: flex;
  background: linear-gradient(#f3f1ef 10px, #fff 100px);
}
.sub-text {
  margin-left: 16px;
  font-weight: 200;
  font-size: 1.6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.sidebar-item--main .sidebar-item__header,
.sidebar-item--main .sidebar-item__main {
  background-color: #f3f1ef;
}
/*# sourceMappingURL=src/app/components/layout/sidebar/sidebar-item.css.map */